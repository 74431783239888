import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import FeaturesWithImages from "../components/features_with_images"
import Benefits from "../components/benefits"
import SignupBanner from "../components/signupBanner"
import GeodisTestiomnial from "../components/testimonials/alexander_geodis"
import { graphql, Link } from "gatsby" // to query for image data
import CargoCrewTestiomnial from "../components/testimonials/ebbo_ schopnie"

const SeaPage = ({ data }) => (
  <Layout>
    <SEO
      title="Container calculator"
      description="Fill many containers at once with our 3D loading software. Palletize. Manual Edits. Step-by-step instructions. Start your free trial today."
    />
    <Hero
      image={data.hero}
      title="Container Loading"
      body="Interactive load plans with our sea container calculator"
      className="bg-white"
      customers={[
        data.kn,
        data.cmacgm,
        data.bollore,
        data.nippon,
        data.dsv,
        data.yusen,
        data.geodis,
      ]}
    />
    <GeodisTestiomnial className="text-white bg-primary" />
    <FeaturesWithImages
      features={[
        {
          title: "Mixed containers",
          image: data.mixed,
          content: (
            <span>
              You select what kind of equipment is available from our{" "}
              <Link
                to="/equipment-library/"
                className="text-blue-900 hover:underline"
              >
                library
              </Link>{" "}
              - and Cargo-Planner will select the best combination of
              containers. Which containers gets used are based on factors like
              used volume, payload and custom rules. You can also assign costs
              per container type which lets the tool choose the most cost
              effective setup
            </span>
          ),
        },
        {
          title: "Prioritize Center of Gravity",
          image: data.cog,
          content: (
            <span>
              The stability of the container can be improved by prioritizing the
              Center of Gravity (CoG) when loading your cargoes. This feature
              will make sure that Center of Gravity will be within 10% from the
              center of the container.
            </span>
          ),
        },
        {
          title: "Load on Flat racks",
          image: data.fr,
          content: (
            <span>
              Do you have Out of Gauge cargoes? With Flat Rack containers,
              platforms and MAFI's you can load overwidth and overheight
              cargoes, at the same time as the Centre Of Gravity will be taken
              into consideration. You can easily create your own custom
              containers with corner posts and walls. Perfect for Project cargo
              shipments.
            </span>
          ),
        },
        {
          title: "Load on Open tops",
          image: data.ot,
          content: (
            <span>
              Use our Open top containers (or create your own) to load cargoes
              too tall to be loaded into normal dry van containers. Cargo
              Planner will automatically select the most appropriate containers.
              If a container does not have an open roof or open walls, the tool
              will not load cargoes that cannot be loaded through the provided
              door size
            </span>
          ),
        },
        {
          title: "Load with pallets",
          image: data.pallets,
          content: (
            <span>
              If your cargoes are not palletized, you can select a pallet type
              and then load your cargoes on pallets before loading them on
              containers, trailers (or even other pallets!).
              <br />
              <br />
              You can even load "out of gauge" cargoes on pallets.
            </span>
          ),
        },
        {
          title: "Breakbulk vessels",
          image: data.vessel,
          content: (
            <span>
              You can easily design custom vessels by selecting and combining sets of cargo holds, tween decks and benches. 
              Whether you're transporting heavy machinery or oversized cargo, our software will load all cargoes instantly. Create your breakbulk vessel today with our <b>Set Builder</b>.
            </span>
          )
        }
      ]}
    ></FeaturesWithImages>
    <Benefits></Benefits>
    <CargoCrewTestiomnial />
    <SignupBanner></SignupBanner>
  </Layout>
)

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`

export const query = graphql`
  {
    bollore: file(relativePath: { eq: "customers/bollore.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    cmacgm: file(relativePath: { eq: "customers/cmacgm.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    geodis: file(relativePath: { eq: "customers/geodis.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    kerry: file(relativePath: { eq: "customers/kerry.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    kn: file(relativePath: { eq: "customers/kn.jpg" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    nippon: file(relativePath: { eq: "customers/nippon.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    dsv: file(relativePath: { eq: "customers/dsv.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    yusen: file(relativePath: { eq: "customers/yusen.jpg" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    pallets: file(
      relativePath: { eq: "containers/container-with-pallets.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    fr: file(
      relativePath: { eq: "containers/flatrack-with-oversized-cargoes.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    ot: file(relativePath: { eq: "containers/opentop-with-tall-cargoes.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    hero: file(relativePath: { eq: "containers/40hc-with-mixed-items.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    mixed: file(
      relativePath: { eq: "loadplans/optimization-with-mixed-containers.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    cog: file(
      relativePath: { eq: "loadplans/prioritize-center-of-gravity.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    vessel: file(
      relativePath: { eq: "containers/vessel-with-tween-decks.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

export default SeaPage
